import React, {FunctionComponent, useState} from 'react'
import EoyJadeOfferGraphic                  from '../assets/custom-pages/eoy-jade-offer-graphic-2021.svg'
import JadeLogoWhite                        from '../assets/logos/jade-logo-white.svg'
import JadeXmasLogo                         from '../assets/logos/jade-xmas-logo.svg'

import VictorianReportsLogo      from '../assets/logos/vr-logo.svg'
import VictorianReportsLogoBlack from '../assets/logos/vr-logo-black.svg'
import LegalResearchTrainingIcon from '../assets/icons/training-icon-whiteoutlines.svg'
import IntoTheRipCover           from '../assets/custom-pages/into-the-rip.jpg'
import IdeasToSaveYourLifeCover  from '../assets/custom-pages/ideas-to-save-your-life.jpg'
import JadeFinalistAward         from '../assets/custom-pages/jade-finalist-award.png'


import '../styles/layout.css'
import SEO                       from '../components/seo'


const EndOfYearOffer2021: FunctionComponent = (props) => {

    const [showDecisionModal, setShowDecisionModal] = useState(false)

    const vrCampaignCode: string = 'JD-T463-102'
    const bookCampaignCode: string = 'JD-T463-980'


    const renderChoiceModal = () => {
        return (
            <section onClick={() => setShowDecisionModal(!showDecisionModal)} style={{background: 'rgb(0,0,0,0.1)'}}
                     className="absolute">
                <div className="fixed z-10 inset-0 overflow-y-auto" aria-labelledby="modal-title" role="dialog"
                     aria-modal="true">
                    <div
                        className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">

                        <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
                             aria-hidden="true"></div>

                        <span className="hidden sm:inline-block sm:align-middle sm:h-screen"
                              aria-hidden="true">&#8203;</span>
                        <div
                            className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
                            <div>

                                <div className="mt-3 text-center sm:mt-5">
                                    <h3 className="text-lg leading-6 font-medium text-gray-900" id="modal-title">
                                        Please confirm your choice
                                    </h3>

                                </div>
                            </div>
                            <div className="mt-5 sm:mt-6">
                                <a href={`https://jade.io/shop/co/campaign/${vrCampaignCode}`}
                                   target="_blank"
                                   className="w-full transition-colors text-center py-8 px-2 inline-flex justify-center rounded-md px-4 py-2 bg-heading-green text-base font-medium text-white hover:bg-gray-700 focus:outline-none focus:ring-black sm:col-start-2  text-md">
                                    I want to upgrade to an Annual JADE Professional subscription and get access to the
                                    Victorian Reports
                                </a>
                            </div>
                            <div className="mt-5 sm:mt-6">
                                <a href={`https://jade.io/shop/co/campaign/${bookCampaignCode}`}
                                   target="_blank"
                                   className="w-full transition-colors text-center py-8 px-2 inline-flex justify-center rounded-md border border-heading-green shadow-sm px-4 py-2 bg-white text-base font-medium text-heading-green hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-black sm:col-start-2  text-md">
                                    I want to upgrade to an Annual JADE Professional subscription and would like to
                                    receive complementary copy of either Into the Rip or Ideas to Save Your Life
                                </a>
                            </div>
                            <div className="mt-6 flex flex-col gap-y-6">
                                <p className="text-sm text-gray-500 mt-1 text-center">
                                    *offers are subject to the <a className="underline" target="_blank"
                                                                  href="/policies">terms and conditions</a>.
                                </p>
                                <button className="w-2/5 text-sm bg-gray-100 text-gray-500 px-4 py-2 rounded">
                                    &larr; back
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        )
    }

    const renderHeroSection = () => (
        <section
            className="max-w-screen-2xl mx-auto py-8 px-4 sm:px-6 lg:px-8">
            <div className="overflow-hidden lg:grid grid lg:grid-cols-2 lg:gap-4">
                <div className="lg:w-4/5 pt-10 pb-12 sm:pt-16  lg:py-16 lg:pr-0 xl:py-20 ">
                    <div className="lg:self-center">
                        <h2 className="text-3xl font-bold sm:text-5xl text-jade-shades-light-blue">
                            END OF YEAR OFFER
                        </h2>
                        <p className="md:w-4/5 mt-4 text-2xl text-white">
                            Upgrade to JADE Professional for $695 and receive
                            <span className={'font-bold'}> a free gift!</span>
                        </p>
                        <p className="md:w-4/5 mt-4 text-2xl text-white">
                            Choose a complimentary 3-month digital subscription to
                            the Victorian Reports, OR select a book for your holiday reading.
                        </p>
                        <button type={'button'} onClick={() => setShowDecisionModal(true)}
                                className="w-4/5 mt-8 py-4 text-xl justify-center border border-transparent rounded-md shadow px-6 py-3 inline-flex text-base font-medium bg-baby-blue text-white">
                            Take up special
                        </button>
                    </div>
                </div>
                <div className="lg:flex hidden justify-end items-center">
                    <button className="w-full" onClick={() => setShowDecisionModal(true)}>
                        <img
                            className="max-w-3xl"
                            src={EoyJadeOfferGraphic}
                            alt="Wireframe image of a legal case displayed in JADE.io"/>
                    </button>
                </div>
            </div>
        </section>
    )

    const renderTitleBlockSection = () => (
        <div className="bg-spanish-green">
            <div className="max-w-2xl mx-auto px-4 sm:px-6 lg:px-8 pb-4 rounded-lg">
                <div className="lg:text-center pt-8 pb-16">
                    <img alt={'JADE logo'} className="w-48 mx-auto mb-4" src={JadeLogoWhite}/>
                    <h2 className="text-white text-center mt-2 text-3xl leading-8 font-medium tracking-tight text-gray-900 sm:text-3xl">
                        Unlock Professional legal research tools which save time
                        & energy and allow you to be more effective.
                    </h2>
                    <p className="text-white text-center mt-4 max-w-xl text-xl text-gray-500 lg:mx-auto">
                        Find relevant legal material quicker and more easily with
                        advanced search tools and filters.
                    </p>
                    <p className="text-white text-center mt-4 max-w-xl text-xl text-gray-500 lg:mx-auto">
                        Save & share your own library of annotated content to quickly recall research
                        when you need.
                    </p>
                    <p className="text-white text-center mt-4 max-w-xl text-xl text-gray-500 lg:mx-auto">
                        Efficiently analyse legal principle with visualisation tools that help you find and
                        map cases, citations, precedents and provisions of legislation
                    </p>
                </div>
            </div>
        </div>
    )

    const renderDealSection = () => (
        <section className="bg-white xl:flex xl:flex-row xl:content-center">
            <div className="max-w-2xl mx-auto px-4 sm:px-6 xl:px-8 py-12g rounded-lg">
                <div className="text-center pt-24 pb-16 flex flex-col justify-center">
                    <img alt={'The Victorian Reports logo'} className="w-60 mx-auto"
                         src={VictorianReportsLogoBlack}/>
                    <h2 className="text-heading-green text-center mt-8 text-3xl leading-8 font-bold text-gray-900 sm:text-3xl">
                        The Victorian Reports
                    </h2>
                    <p className="text-center mt-6 max-w-xl text-xl text-gray-500 lg:mx-auto">
                        The Victorian Reports fully integrate with your JADE Professional account for a
                        seamless user experience.
                    </p>
                    <p className="text-center mt-6 max-w-xl text-xl text-gray-500 lg:mx-auto">
                        BarNet’s subsidiary Little William Bourke has been the publisher of the Print and
                        Digital editions of the Victorian Reports since 2016.
                    </p>
                    <a href={`https://jade.io/shop/co/campaign/${vrCampaignCode}`} target="_blank"
                       className="bg-heading-green mt-5 py-4 mt-6 text-xl justify-center border border-transparent rounded-md shadow px-6 py-3 inline-flex text-base font-medium text-white">
                        Take up special for $695
                    </a>
                    <p className="text-sm text-gray-500 mt-1 text-center">
                        *subject to the <a className="underline" target="_blank" href="/policies">terms and
                        conditions</a>.
                    </p>
                    <div className="mt-8">
                        <a className="font-medium underline text-heading-green" target="_blank"
                           href={'https://victorianreports.com.au'}>
                            Visit Victorian Reports
                        </a>
                    </div>
                </div>
            </div>
            <p className="text-4xl xl:py-80 pb-4 font-medium text-heading-green text-center">OR</p>

            <div className="w-5/6 mt-16 max-w-2xl mx-auto bg-white">
                <div className="flex flex-row py-6 pl-8 items-center justify-center">
                    <a className="w-60 max-w-md justify-center" target="_blank"
                       href={`https://jade.io/shop/co/campaign/${bookCampaignCode}`}>
                        <img className="w-4/5"
                             alt="Book cover of Into the Rip by Damien Cave"
                             src={IntoTheRipCover}/>
                    </a>
                    <a className="w-60 justify-center" target="_blank"
                       href={`https://jade.io/shop/co/campaign/${bookCampaignCode}`}>
                        <img className="w-4/5"
                             alt="Book cover of Ideas to Save Your Life by Michael McGirr"
                             src={IdeasToSaveYourLifeCover}/>
                    </a>
                </div>
                <div className="text-center mt-6 max-w-xl text-xl text-gray-500 lg:mx-auto">
                    <p>
                        Prefer some holiday reading instead? Choose a book for the break. <span
                        className={'italic'}>Into the Rip</span> by Damien Cave or <span
                        className={'italic'}>Ideas to Save Your Life</span> by Michael McGirr.
                    </p>

                </div>

                <div className="flex flex-col items-center mx-10">
                    <a className="py-4 mt-4 xl:w-5/6 text-xl justify-center text-center border border-heading-green rounded-md shadow px-6 py-3 inline-flex text-base font-medium text-heading-green"
                       href={`https://jade.io/shop/co/campaign/${bookCampaignCode}`} target="_blank">
                        Upgrade and receive a book
                    </a>
                    <p className="text-sm text-gray-500 mt-1 mb-8 text-center">
                        *subject to the <a className="underline" target="_blank" href="/policies">terms
                        and conditions</a>.
                    </p>
                </div>
            </div>
        </section>
    )

    const renderWhatsIncludedSection = () => (
        <section className="max-w-screen-2xl mx-auto pb-8  ">
            <h2 className="text-3xl text-center font-bold my-12 text-white">
                What's included?
            </h2>
            <section className="bg-white flex flex-col sm:mx-6 lg:mx-8 mb-10 justify-between ">
                <div className="h-24 min-h-24 bg-spanish-green flex place-content-center">
                    <img alt="JADE logo" className="w-24" src={JadeLogoWhite}/>
                </div>
                <div className="grid grid-cols-1 sm:grid-cols-2 px-12 pt-12 pb-4">
                    <div>
                        <h3 className="text-2xl font-medium mb-2">
                            JADE Professional
                        </h3>
                        <p className="mb-4">
                            Annual Subscription
                        </p>
                        <p className="mb-2 text-lg font-medium text-gray-800">
                            $695 inc. GST
                        </p>
                        <p className="text-gray-400 pb-1 text-lg">
                            Full access to JADE’s free and Professional research tools for legal
                            practitioners
                        </p>
                    </div>


                    <div className="flex flex-col items-center justify-center px:8 md:px-16">
                        <img className="w-32 h-32" src={JadeFinalistAward}/>
                        <p className="text-sm text-gray-400 text-center ">
                            JADE was a finalist in the 2021 Australian Digital Technology Awards.
                        </p>
                    </div>
                </div>
                <div className="px-12 pt-4">
                    <h4 className="capitalize font-bold text-sm mb-4">
                        Included
                    </h4>
                    <ul className="grid grid-cols-1 sm:grid-cols-2 pb-5">
                        <li className="flex space-x-3 pb-4">
                            <svg className="flex-shrink-0 h-5 w-5 text-green-500"
                                 xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"
                                 fill="currentColor"
                                 aria-hidden="true">
                                <path fillRule="evenodd"
                                      d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                                      clipRule="evenodd"/>
                            </svg>
                            <span className="text-sm text-gray-500">
                                            Citations to legislation at a subsection level.
                                        </span>
                        </li>
                        <li className="flex space-x-3 pb-4">
                            <svg className="flex-shrink-0 h-5 w-5 text-green-500"
                                 xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"
                                 fill="currentColor"
                                 aria-hidden="true">
                                <path fillRule="evenodd"
                                      d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                                      clipRule="evenodd"/>
                            </svg>
                            <span className="text-sm text-gray-500">
                                            Case Visualisations - identify similar cases, track precedents and view parallel citations.
                                        </span>
                        </li>
                        <li className="flex space-x-3 pb-4">
                            <svg className="flex-shrink-0 h-5 w-5 text-green-500"
                                 xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"
                                 fill="currentColor"
                                 aria-hidden="true">
                                <path fillRule="evenodd"
                                      d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                                      clipRule="evenodd"/>
                            </svg>
                            <span className="text-sm text-gray-500">
                                            Legislation Visualisations - visual history of legislation, compare versions of a legislation provision with any amendments highlighted.
                                        </span>
                        </li>
                        <li className="flex space-x-3 pb-4">
                            <svg className="flex-shrink-0 h-5 w-5 text-green-500"
                                 xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"
                                 fill="currentColor"
                                 aria-hidden="true">
                                <path fillRule="evenodd"
                                      d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                                      clipRule="evenodd"/>
                            </svg>
                            <span className="text-sm text-gray-500">
                                            Citation checking - securely upload documents to check citations and create links to cases and legislation cited.
                                        </span>
                        </li>
                        <li className="flex space-x-3 pb-4">
                            <svg className="flex-shrink-0 h-5 w-5 text-green-500"
                                 xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"
                                 fill="currentColor"
                                 aria-hidden="true">
                                <path fillRule="evenodd"
                                      d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                                      clipRule="evenodd"/>
                            </svg>
                            <span className="text-sm text-gray-500">
                                            Document sharing - export and share your uploaded documents with embedded links.
                                        </span>
                        </li>
                        <li className="flex space-x-3 pb-4">
                            <svg className="flex-shrink-0 h-5 w-5 text-green-500"
                                 xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"
                                 fill="currentColor"
                                 aria-hidden="true">
                                <path fillRule="evenodd"
                                      d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                                      clipRule="evenodd"/>
                            </svg>
                            <span className="text-sm text-gray-500">
                                            Focus matches - view only the sections of a document that contain your keywords or phrases.
                                        </span>
                        </li>
                        <li className="flex space-x-3 pb-4">
                            <svg className="flex-shrink-0 h-5 w-5 text-green-500"
                                 xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"
                                 fill="currentColor"
                                 aria-hidden="true">
                                <path fillRule="evenodd"
                                      d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                                      clipRule="evenodd"/>
                            </svg>
                            <span className="text-sm text-gray-500">
                                            Powerful search features such as Citator and Advanced Search.
                                        </span>
                        </li>
                        <li className="flex space-x-3 pb-4">
                            <svg className="flex-shrink-0 h-5 w-5 text-green-500"
                                 xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"
                                 fill="currentColor"
                                 aria-hidden="true">
                                <path fillRule="evenodd"
                                      d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                                      clipRule="evenodd"/>
                            </svg>
                            <span className="text-sm text-gray-500">
                                            Create Jade alerts from advanced saved searches.
                                        </span>
                        </li>
                        <li className="flex space-x-3 pb-4">
                            <svg className="flex-shrink-0 h-5 w-5 text-green-500"
                                 xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"
                                 fill="currentColor"
                                 aria-hidden="true">
                                <path fillRule="evenodd"
                                      d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                                      clipRule="evenodd"/>
                            </svg>
                            <span className="text-sm text-gray-500">
                                            Full access to the Jade citator.
                                        </span>
                        </li>
                        <li className="flex space-x-3 pb-4">
                            <svg className="flex-shrink-0 h-5 w-5 text-green-500"
                                 xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"
                                 fill="currentColor"
                                 aria-hidden="true">
                                <path fillRule="evenodd"
                                      d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                                      clipRule="evenodd"/>
                            </svg>
                            <span className="text-sm text-gray-500">
                                            Jademarks - create personalised libraries and annotate content.
                                        </span>
                        </li>
                    </ul>
                </div>
                <div className="pt-4 px-12 flex flex-col items-center">
                    <button onClick={() => setShowDecisionModal(true)}
                            className="w-full bg-spanish-green text-xl font-medium text-center text-white px-6 py-3 rounded">
                        Upgrade and save now
                    </button>
                    <p className="text-sm text-gray-500 pb-5 mt-1 text-center">
                        *subject to the <a className="underline" target="_blank" href="/policies">terms
                        and conditions</a>.
                    </p>
                </div>
            </section>

            <section className=" gap-8 px-4 sm:px-6 lg:px-8">
                <section className="xl:flex xl:flex-row gap-4 w-full justify-between">
                    <section className="bg-white">
                        <div
                            className="col-span-2 h-24 min-h-24 bg-heading-green flex place-content-center">
                            <img alt="Victorian Reports logo" className="w-24 h-20 pt-4"
                                 src={VictorianReportsLogo}/>
                        </div>
                        <div className="grid xl:grid-cols-2 grid-cols-1 pt-5">
                            <div className="flex items-center">
                                <div className="px-16 xl:h-64 h-auto">
                                    <h3 className="text-2xl font-medium mb-2">
                                        The Victorian Reports
                                    </h3>
                                    <p className="mb-4">
                                        3 Month Digital Subscription
                                    </p>
                                    <p className="text-gray-400 pb-6 text-lg">
                                        Integrate your JADE Professional account with the Victorian Reports
                                    </p>
                                </div>
                            </div>
                            <div className="xl:px-6 xl:pt-8 xl:pb-4 px-16 py-8 ">
                                <h4 className="capitalize font-bold text-sm mb-4">
                                    Included
                                </h4>
                                <ul>
                                    <li className="flex space-x-3 pb-4">
                                        <svg className="flex-shrink-0 h-5 w-5 text-green-500"
                                             xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"
                                             fill="currentColor"
                                             aria-hidden="true">
                                            <path fillRule="evenodd"
                                                  d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                                                  clipRule="evenodd"/>
                                        </svg>
                                        <span className="text-sm text-gray-500">
                                            Full Digital Access to the Victorian Reports.
                                        </span>
                                    </li>
                                    <li className="flex space-x-3 pb-4">
                                        <svg className="flex-shrink-0 h-5 w-5 text-green-500"
                                             xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"
                                             fill="currentColor"
                                             aria-hidden="true">
                                            <path fillRule="evenodd"
                                                  d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                                                  clipRule="evenodd"/>
                                        </svg>
                                        <span className="text-sm text-gray-500">
                                            Seamless integration and linking between the Victorian Reports and JADE for
                                                an enhanced user experience.
                                        </span>
                                    </li>
                                    <li className="flex space-x-3 pb-4">
                                        <svg className="flex-shrink-0 h-5 w-5 text-green-500"
                                             xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"
                                             fill="currentColor"
                                             aria-hidden="true">
                                            <path fillRule="evenodd"
                                                  d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                                                  clipRule="evenodd"/>
                                        </svg>
                                        <span className="text-sm text-gray-500">
                                            Decisions are fully searchable and linked to later case consideration as soon as the later decision is received
                                        </span>
                                    </li>
                                    <li className="flex space-x-3 pb-4">
                                        <svg className="flex-shrink-0 h-5 w-5 text-green-500"
                                             xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"
                                             fill="currentColor"
                                             aria-hidden="true">
                                            <path fillRule="evenodd"
                                                  d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                                                  clipRule="evenodd"/>
                                        </svg>
                                        <span className="text-sm text-gray-500">
                                            PDF copies of decisions designed for greater utility and readability
                                        </span>
                                    </li>
                                </ul>

                            </div>

                        </div>
                        <div className="flex flex-col justify-center items-center pb-6 my-4 mx-10">
                            <a className="w-full bg-heading-green text-lg font-medium text-center text-white px-6 py-3 rounded"
                               href={`https://jade.io/shop/${vrCampaignCode}`} target="_blank">
                                Upgrade and get 3 months access
                            </a>
                            <p className="text-sm text-gray-500 mt-1 self-end">
                                *subject to the <a className="underline" target="_blank"
                                                   href="/policies">terms and conditions</a>.
                            </p>
                        </div>
                    </section>

                    <p className="text-2xl px-10 xl:py-72 pb-4 font-medium text-white text-center">OR</p>

                    <section className="pl-0 w-full max-w-6xl pt-7 bg-white">
                        <div className="flex flex-row py-6 pl-8 items-center justify-center">
                            <a className="w-60 max-w-md justify-center" target="_blank"
                               href={`https://jade.io/shop/co/campaign/${bookCampaignCode}`}>
                                <img className="w-4/5"
                                     alt="Book cover of Into the Rip by Damien Cave"
                                     src={IntoTheRipCover}/>
                            </a>
                            <a className="w-60 justify-center" target="_blank"
                               href={`https://jade.io/shop/co/campaign/${bookCampaignCode}`}>
                                <img className="w-4/5"
                                     alt="Book cover of Ideas to Save Your Life by Michael McGirr"
                                     src={IdeasToSaveYourLifeCover}/>
                            </a>
                        </div>
                        <div className="px-8 py-5 flex flex-col items-end justify-center">
                            <p className="text-center text-md text-gray-500">
                                Prefer some holiday reading instead? Choose a book for the break. <span
                                className={'italic'}>Into the Rip</span> by Damien Cave or <span
                                className={'italic'}>
                                    Ideas to Save Your Life</span> by Michael McGirr.
                            </p>

                        </div>

                        <div className="flex flex-col items-center my-4 mx-10">
                            <a className="w-full border-heading-green border-2 text-lg font-medium text-center text-heading-green px-6 py-3 rounded"
                               href={`https://jade.io/shop/co/campaign/${bookCampaignCode}`} target="_blank">
                                Upgrade and receive a book
                            </a>
                            <p className="text-sm text-gray-500 mt-1 pb-6 self-end">
                                *subject to the <a className="underline" target="_blank" href="/policies">terms
                                and conditions</a>.
                            </p>
                        </div>
                    </section>
                </section>
                <section className="my-10">
                    <div className="h-24 min-h-24 bg-baby-blue flex place-content-center">
                        <img alt="Legal Research Logo" className="h- 16 w-16"
                             src={LegalResearchTrainingIcon}/>
                    </div>
                    <section className="bg-white xl:flex xl:items-center">
                        <div className="xl:flex-1">
                            <div className="px-16 py-8">
                                <h3 className="text-2xl font-medium mb-2">
                                    Legal Research Training
                                </h3>
                                <p className="text-gray-400 text-lg">
                                    Subscribers receive research training by our expert researchers
                                </p>
                            </div>
                        </div>
                        <div className="xl:flex-1 px-6 py-6 flex items-center">
                            <ul>
                                <li className="flex space-x-3 pb-4">
                                    <svg className="flex-shrink-0 h-5 w-5 text-green-500"
                                         xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"
                                         fill="currentColor"
                                         aria-hidden="true">
                                        <path fillRule="evenodd"
                                              d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                                              clipRule="evenodd"/>
                                    </svg>
                                    <span className="text-sm text-gray-500">
                                            Invitations to our <b>live online professional training</b> by expert researchers. Includes all free and JADE Professional features using real cases and legislation, and training to seamlessly navigate the Victorian Reports.
                                        </span>
                                </li>
                                <li className="flex space-x-3 pb-4">
                                    <svg className="flex-shrink-0 h-5 w-5 text-green-500"
                                         xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"
                                         fill="currentColor"
                                         aria-hidden="true">
                                        <path fillRule="evenodd"
                                              d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                                              clipRule="evenodd"/>
                                    </svg>
                                    <span className="text-sm text-gray-500">
                                                <b>Access online training resources</b>, feature demonstration videos, help guides and FAQ’s.
                                        </span>
                                </li>
                                <li className="flex space-x-3 pb-4">
                                    <svg className="flex-shrink-0 h-5 w-5 text-green-500"
                                         xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"
                                         fill="currentColor"
                                         aria-hidden="true">
                                        <path fillRule="evenodd"
                                              d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                                              clipRule="evenodd"/>
                                    </svg>
                                    <span className="text-sm text-gray-500">
                                            <b>Login to chat </b>and ask a question to a member of the JADE editorial team
                                        </span>
                                </li>
                                <li className="flex space-x-3 pb-4">
                                    <svg className="flex-shrink-0 h-5 w-5 text-green-500"
                                         xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"
                                         fill="currentColor"
                                         aria-hidden="true">
                                        <path fillRule="evenodd"
                                              d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                                              clipRule="evenodd"/>
                                    </svg>
                                    <span className="text-sm text-gray-500">
                                            <b>Book live research training </b>and
                                            demonstrations for you and your
                                            colleagues
                                        </span>
                                </li>
                            </ul>
                        </div>
                    </section>
                </section>
            </section>
        </section>
    )

    const renderTitleBlockWithFeatureListSection = () => (
        <section className="bg-white">
            <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12 bg-white rounded-lg">
                <div className="lg:text-center py-8">
                    <h2 className="mt-2 text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
                        Australia's best legal research platform
                    </h2>
                    <p className="mt-4 max-w-2xl text-xl text-gray-500 lg:mx-auto">
                        Purpose-built for professional legal research.
                    </p>
                    <p className="mt-4 max-w-2xl text-xl text-gray-500 lg:mx-auto">
                        Created by legal professionals to do what other tools don’t.
                    </p>
                </div>
                <div className="py-12">
                    <dl className="space-y-10 md:space-y-0 md:grid md:grid-cols-3 md:gap-x-8 md:gap-y-10">
                        <div className="flex">
                            <div className="flex-shrink-0">
                                <div
                                    className="flex items-center justify-center h-12 w-12 rounded-md bg-spanish-green text-white">
                                    <svg className="h-8 w-8" xmlns="http://www.w3.org/2000/svg" fill="none"
                                         viewBox="0 0 24 24" stroke="currentColor">
                                        <path strokeLinecap="round" strokeLinejoin="round"
                                              strokeWidth="2"
                                              d="M5 13l4 4L19 7"/>
                                    </svg>
                                </div>
                            </div>
                            <div className="ml-4">
                                <dt className="text-lg leading-6 font-medium text-gray-900">
                                    Save time. Save money.
                                </dt>
                                <dd className="mt-2 text-gray-400">
                                    Advanced features you won’t find anywhere else.
                                </dd>
                            </div>
                        </div>
                        <div className="flex">
                            <div className="flex-shrink-0">
                                <div
                                    className="flex items-center justify-center h-12 w-12 rounded-md bg-spanish-green text-white">
                                    <svg className="h-8 w-8" xmlns="http://www.w3.org/2000/svg" fill="none"
                                         viewBox="0 0 24 24" stroke="currentColor">
                                        <path strokeLinecap="round" strokeLinejoin="round"
                                              strokeWidth="2"
                                              d="M5 13l4 4L19 7"/>
                                    </svg>
                                </div>
                            </div>
                            <div className="ml-4">
                                <dt className="text-lg leading-6 font-medium text-gray-900">
                                    Quality information you can trust
                                </dt>
                                <dd className="mt-2 text-base text-gray-400">
                                    Comprehensive coverage of Australian and international legal material,
                                    quality-controlled by legal professionals.
                                </dd>
                            </div>
                        </div>
                        <div className="flex">
                            <div className="flex-shrink-0">
                                <div
                                    className="flex items-center justify-center h-12 w-12 rounded-md bg-spanish-green text-white">
                                    <svg className="h-8 w-8" xmlns="http://www.w3.org/2000/svg" fill="none"
                                         viewBox="0 0 24 24" stroke="currentColor">
                                        <path strokeLinecap="round" strokeLinejoin="round"
                                              strokeWidth="2"
                                              d="M5 13l4 4L19 7"/>
                                    </svg>
                                </div>
                            </div>
                            <div className="ml-4">
                                <dt className="text-lg leading-6 font-medium text-gray-900">
                                    Privacy assured
                                </dt>
                                <dd className="mt-2 text-base text-gray-400">
                                    Every search is anonymised with encryption, and all our servers are in
                                    Australia.
                                </dd>
                            </div>
                        </div>
                    </dl>
                </div>
            </div>
        </section>
    )

    const renderCallToActionSection = () => (
        <section className="bg-gray-50">
            <div
                className="max-w-7xl mx-auto py-12 px-4 sm:px-6 lg:py-24 lg:px-8 lg:flex lg:items-center lg:justify-between">
                <h2 className="text-3xl font-extrabold tracking-tight text-gray-900 md:text-4xl">
                    <span className="block">Support an Australian Not-for-Profit</span>
                    <span className="block mt-4 max-w-lg font-medium text-lg text-spanish-green">
                        Your subscription allows us to support the legal community with our free open access to legal information at <a
                        target="_blank"
                        href="https://jade.io">JADE.io</a>, and continuously improve JADE Professional.
                        We don’t ask for donations or receive government grants.
                                </span>
                </h2>
                <div className="mt-8 flex lg:mt-0 lg:flex-shrink-0">
                    <div className="inline-flex rounded-md shadow">
                        <button type={'button'} onClick={() => setShowDecisionModal(true)}
                                className="inline-flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-white bg-spanish-green hover:bg-green-700">
                            Subscribe today!
                        </button>
                    </div>
                </div>
            </div>
        </section>
    )

    const renderFeatureListSection = () => (
        <section className="max-w-7xl mx-auto py-8 px-4 sm:px-6 lg:px-8">
            <div
                className="bg-white rounded-lg shadow-xl mx-auto px-8 py-16 sm:px-6 sm:pt-20 sm:pb-24 lg:max-w-7xl lg:pt-24 lg:px-8">
                <h2 className="text-center text-3xl font-extrabold text-black tracking-tight">
                    Unleash the power of JADE with our Professional features
                </h2>
                <div
                    className="mt-12 px-2 grid grid-cols-1 gap-x-6 gap-y-12 sm:grid-cols-2 lg:mt-16 lg:grid-cols-4 lg:gap-x-8 lg:gap-y-16">
                    <div>
                        <div>
          <span className="flex items-center justify-center h-12 w-12 rounded-md bg-white bg-opacity-10">
             <img className="h-12 w-12 text-black" src="/assets/feature-icons/jade-icon-legislation.svg"
                  alt="Icon showing statute books"/>
          </span>
                        </div>
                        <div className="mt-6">
                            <h3 className="text-lg font-bold text-black">Legislation</h3>
                            <p className="mt-2 text-base text-spanish-green">
                                Subsection level citations for legislation at the click of a button.
                            </p>
                        </div>
                    </div>
                    <div>
                        <div>
          <span className="flex items-center justify-center h-12 w-12 rounded-md bg-white bg-opacity-10">
            <img className="h-12 w-12 text-black" src="/assets/feature-icons/jade-icon-visualisations.svg"
                 alt="Icon showing a graph data structure with interconnected nodes"/>
          </span>
                        </div>
                        <div className="mt-6">
                            <h3 className="text-lg font-bold text-black">Visualisations</h3>
                            <p className="mt-2 text-base text-spanish-green">
                                Reveal hidden connections between cases and compare versions of legislation.
                            </p>
                        </div>
                    </div>
                    <div>
                        <div>
          <span className="flex items-center justify-center h-12 w-12 rounded-md bg-white bg-opacity-10">
            <img className="h-12 w-12 text-black" src="/assets/feature-icons/jade-icon-citation-checking.svg"
                 alt="Icon showing a document with a magnifying glass on top"/>
          </span>
                        </div>
                        <div className="mt-6">
                            <h3 className="text-lg font-bold text-black">Citation Checking</h3>
                            <p className="mt-2 text-base text-spanish-green">
                                Upload any legal document into MY JADE to check citations.
                            </p>
                        </div>
                    </div>
                    <div>
                        <div>
                          <span
                              className="flex items-center justify-center h-12 w-12 rounded-md bg-white bg-opacity-10">
                            <img className="h-12 w-12 text-black" src="/assets/feature-icons/jade-icon-alerts.svg"
                                 alt="Icon showing an alert bell"/>
                          </span>
                        </div>
                        <div className="mt-6">
                            <h3 className="text-lg font-bold text-black">Advanced JADE Alerts</h3>
                            <p className="mt-2 text-base text-spanish-green">
                                Customisable alerts based on search parameters and frequency you choose.
                                Create your
                                alerts by file number, judge, cases cited, legislation and more.
                            </p>
                        </div>
                    </div>
                    <div>
                        <div>
          <span className="flex items-center justify-center h-12 w-12 rounded-md bg-white bg-opacity-10">
            <img className="h-12 w-12 text-black" src="/assets/feature-icons/jade-icon-jademarks.svg"
                 alt="Icon showing a bookmark with a plus symbol"/>
          </span>
                        </div>
                        <div className="mt-6">
                            <h3 className="text-lg font-bold text-black">Jademarks</h3>
                            <p className="mt-2 text-base text-spanish-green">
                                Tag and annotate a case or legislation. You can search and evaluate your
                                Jademarks using
                                Advanced Search.
                            </p>
                        </div>
                    </div>
                    <div>
                        <div>
          <span className="flex items-center justify-center h-12 w-12 rounded-md bg-white bg-opacity-10">
<img className="h-12 w-12 text-black" src="/assets/feature-icons/jade-icon-briefcase.svg"
     alt="Icon showing a briefcase"/>
          </span>
                        </div>
                        <div className="mt-6">
                            <h3 className="text-lg font-bold text-black">Briefcase</h3>
                            <p className="mt-2 text-base text-spanish-green">
                                Keep every search, document and comment in one streamlined hub.
                            </p>
                        </div>
                    </div>
                    <div>
                        <div>
          <span className="flex items-center justify-center h-12 w-16 rounded-md bg-white bg-opacity-10">
         <img className="h-12 w-16 text-black" src="/assets/feature-icons/jade-icon-casetrace.svg"
              alt="Icon showing two documents with an arrow between them"/>
          </span>
                        </div>
                        <div className="mt-6">
                            <h3 className="text-lg font-bold text-black">Case Trace</h3>
                            <p className="mt-2 text-base text-spanish-green">
                                Our citator gives you a snapshot analysis of citations made, pinpoint
                                references and the
                                most recent citation to the case or Act.
                            </p>
                        </div>
                    </div>
                    <div>
                        <div>
          <span className="flex items-center justify-center h-12 w-12 rounded-md bg-white bg-opacity-10">
            <img className="h-12 w-12 text-black" src="/assets/feature-icons/jade-icon-speak-to-editor.svg"
                 alt="Icon showing two speech bubbles"/>
          </span>
                        </div>
                        <div className="mt-6">
                            <h3 className="text-lg font-bold text-black">Speak to an Editor</h3>
                            <p className="mt-2 text-base text-spanish-green">
                                Speak to a friendly JADE Editor who can help you find what you need.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )

    const renderTestimonialSection = () => (

        <section style={{background: 'url("/assets/dotnoise-light-grey.png") #089444'}}
                 className="bg-spanish-green">
            <div className="max-w-7xl mx-auto md:grid md:grid-cols-2 md:px-6 lg:px-8">
                <div
                    className="py-12 px-4 sm:px-6 md:flex md:flex-col md:py-16 md:pl-0 md:pr-10 md:border-r md:border-green-900 lg:pr-16">
                    <div className="md:flex-shrink-0">
                    </div>
                    <blockquote className="mt-6 md:flex-grow md:flex md:flex-col">
                        <div className="relative text-lg font-medium text-white md:flex-grow">
                            <svg
                                className="absolute top-0 left-0 transform -translate-x-3 -translate-y-2 h-8 w-8 text-gray-600"
                                fill="currentColor" viewBox="0 0 32 32" aria-hidden="true">
                                <path
                                    d="M9.352 4C4.456 7.456 1 13.12 1 19.36c0 5.088 3.072 8.064 6.624 8.064 3.36 0 5.856-2.688 5.856-5.856 0-3.168-2.208-5.472-5.088-5.472-.576 0-1.344.096-1.536.192.48-3.264 3.552-7.104 6.624-9.024L9.352 4zm16.512 0c-4.8 3.456-8.256 9.12-8.256 15.36 0 5.088 3.072 8.064 6.624 8.064 3.264 0 5.856-2.688 5.856-5.856 0-3.168-2.304-5.472-5.184-5.472-.576 0-1.248.096-1.44.192.48-3.264 3.456-7.104 6.528-9.024L25.864 4z"/>
                            </svg>
                            <p className="relative mb-4">
                                As a legal practitioner, quick and easy access to reliable and up to date
                                resources is critical. In the past, I have used platforms that were limited
                                in scope and only catered for some of those key aspects that a legal
                                practitioner really needs.
                            </p>
                            <p className="relative mb-4">
                                After joining JADE, I have not only had great customer service, but have
                                been provided with a platform that is price competitive, easy to use,
                                reliable, up to date and allows me to do so much more!
                            </p>
                            <p className="relative">
                                They also take customer feedback seriously and are always open to hear
                                customer suggestions of how they may improve their system. I look forward to
                                working with Jade for the foreseeable future.
                            </p>
                        </div>
                        <footer className="mt-8">
                            <div className="flex">
                                <div className="">
                                    <div className="text-base font-medium text-white">Nikola Reljic</div>
                                    <div className="text-base font-medium text-gray-300">Law Graduate,
                                        Dennis Family
                                        Corporation
                                    </div>
                                </div>
                            </div>
                        </footer>
                    </blockquote>
                </div>
                <div
                    className="py-12 px-4 border-t-2 border-green-900 sm:px-6 md:py-16 md:pr-0 md:pl-10 md:border-t-0 md:border-l lg:pl-16">
                    <div className="md:flex-shrink-0">
                    </div>
                    <blockquote className="mt-6 md:flex-grow md:flex md:flex-col">
                        <div className="relative text-lg font-medium text-white md:flex-grow">
                            <svg
                                className="absolute top-0 left-0 transform -translate-x-3 -translate-y-2 h-8 w-8 text-gray-600"
                                fill="currentColor" viewBox="0 0 32 32">
                                <path
                                    d="M9.352 4C4.456 7.456 1 13.12 1 19.36c0 5.088 3.072 8.064 6.624 8.064 3.36 0 5.856-2.688 5.856-5.856 0-3.168-2.208-5.472-5.088-5.472-.576 0-1.344.096-1.536.192.48-3.264 3.552-7.104 6.624-9.024L9.352 4zm16.512 0c-4.8 3.456-8.256 9.12-8.256 15.36 0 5.088 3.072 8.064 6.624 8.064 3.264 0 5.856-2.688 5.856-5.856 0-3.168-2.304-5.472-5.184-5.472-.576 0-1.248.096-1.44.192.48-3.264 3.456-7.104 6.528-9.024L25.864 4z"/>
                            </svg>
                            <p className="relative">
                                I co-author a legal reference website that relies heavily on Jade for the purposes of
                                case and legislation collation and reporting. We reached out to Jade to see whether it
                                could provide certain search functionality and, within an exceedingly short period of
                                time, Jade implemented various changes in response to our feedback. These changes have
                                dramatically improved our capacity to use Jade in our work and we are tremendously
                                grateful for the responsiveness we received.
                            </p>
                        </div>
                        <footer className="mt-8">
                            <div className="flex">
                                <div className="">
                                    <div className="text-base font-medium text-white">Maya Narayan</div>
                                    <div className="text-base font-medium text-gray-200">Co-author, COVID-19
                                        and the Law of
                                        Australia
                                    </div>
                                </div>
                            </div>
                        </footer>
                    </blockquote>
                </div>
            </div>
        </section>

    )
    return (
        <>
            {
                showDecisionModal ? renderChoiceModal() : null
            }
            <SEO
                title={'JADE and Victorian Reports EOFY Deal - Open Law'}
                description={'Upgrade to a JADE Professional annual subscription and receicve three months access to the Victorian Reports'}
                keywords={['legal research', 'law reports', 'authorised reports', 'law']}
            />
            <div className="min-h-screen bg-jade-shades-dark-dark-green">
                <header className="z-20">
                    <div className="max-w-screen-2xl mx-auto px-4 sm:px-6 lg:px-8">
                        <div className="flex justify-between h-32">
                            <div className="flex lg:px-0">
                                <div className="flex-shrink-0 flex items-center">
                                    <a className="serif flex flex-row items-center" href="https://jade.io">
                                        <img className="h-16 w-auto" src={JadeXmasLogo}
                                             alt="JADE logo with christmas lights"/>
                                    </a>
                                </div>
                                <div className="flex-1"></div>
                            </div>
                            <nav aria-label="Global"
                                 className="hidden lg:ml-6 sm:flex lg:items-center lg:space-x-4 flex-wrap">
                                <div className="flex-1 flex items-center justify-center px-2 lg:ml-2 lg:justify-end">
                                    <a href="https://jade.io"
                                       target="_blank"
                                       className="inline-flex items-center px-24 py-4 border border-spanish-green text-lg leading-4 font-medium rounded-md shadow-sm text-spanish-green hover:text-white hover:bg-spanish-green focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500">
                                        Visit JADE
                                    </a>
                                </div>
                            </nav>
                        </div>
                    </div>
                </header>
                <main className="z-10">
                    {/*
                ============
                HERO SECTION
                ============
                */}
                    {
                        renderHeroSection()
                    }
                    {
                        renderTitleBlockSection()
                    }
                    {
                        renderDealSection()
                    }
                    {
                        renderWhatsIncludedSection()
                    }
                    {
                        renderTitleBlockWithFeatureListSection()
                    }
                    {
                        renderCallToActionSection()
                    }
                    {
                        renderFeatureListSection()
                    }
                    {
                        renderTestimonialSection()
                    }
                </main>
                <footer className="bg-gray-900">
                    <div className="max-w-7xl mx-auto py-12 px-4 overflow-hidden sm:px-6 lg:px-8">

                        <div className="mt-8 flex justify-center space-x-6">

                            <a target="_blank" href="https://twitter.com/barnetjade"
                               className="text-gray-400 hover:text-gray-500">
                                <span className="sr-only">Twitter</span>
                                <svg className="h-8 w-8" fill="currentColor" viewBox="0 0 24 24" aria-hidden="true">
                                    <path
                                        d="M8.29 20.251c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.348 8.348 0 0022 5.92a8.19 8.19 0 01-2.357.646 4.118 4.118 0 001.804-2.27 8.224 8.224 0 01-2.605.996 4.107 4.107 0 00-6.993 3.743 11.65 11.65 0 01-8.457-4.287 4.106 4.106 0 001.27 5.477A4.072 4.072 0 012.8 9.713v.052a4.105 4.105 0 003.292 4.022 4.095 4.095 0 01-1.853.07 4.108 4.108 0 003.834 2.85A8.233 8.233 0 012 18.407a11.616 11.616 0 006.29 1.84"/>
                                </svg>
                            </a>

                        </div>
                        <p className="mt-8 text-center text-base text-gray-400">
                            © 2002 - 2021 BarNetwork Pty Limited (ABN 32 092 121 198). All rights reserved.
                        </p>
                    </div>
                </footer>
            </div>
        </>
    )

}

export default EndOfYearOffer2021
